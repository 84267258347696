<template>
  <div class="Table my-8">

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="isTop ? ranking:desserts"
      item-key="naviId"
      :sort-by="sortbyList"
      :sort-desc="sortdescList"
      :class=" isTop? 'elevation-0' : 'elevation-1' "
      :search="search"
      :loading="loading"
      :show-select="!isTop"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :hide-default-header="$vuetify.breakpoint.xs"
      hide-default-footer
    >



    <!-- カスタムフッター -->
    <template v-if="!isTop" v-slot:footer  >
      <v-row class="text-center pt-2 align-center mx-2 mt-0" width="90%" wrap style="border-top:1px solid #bbbbbb;">
        <v-col class="text-truncate opacity-07 body-2 mb-0 pa-0" cols="12" md="12">
              {{ pageText }} 
        </v-col>
        <v-col cols="12" md="12" class="mb-3 mt-0">
            <v-pagination
                v-model="page"
                color="narekomu"
                class="mt-0"
                :length="pageCount">
            </v-pagination>
        </v-col>
      </v-row>
    </template>

       <!-- 名前 -->
      <template v-slot:item.naviName="{ item }" >
        <v-hover v-slot="{ hover }" style="cursor:pointer;">
          <div style="width:100%; height:100%">
            <v-row v-if="!hover | isTop" class="text-left ma-0 pa-0" align-content="center" style="width:100%; height:100%">
              <router-link 
                style="color:#4ECDC4;"
                :id="item.naviId"
                :to="{ 
                  name:'naviDetail',
                  params:{id : item.naviId},
                  query:{hash:hashText}
                }"
              >
                {{item.naviName}}
              </router-link>
            </v-row>
            <div v-else class="d-flex pl-3" style="width:100%; height:100%">

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }"> 
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="mr-4 my-auto"
                    @click="goNaviDetail(item)"
                  >
                    <v-icon>{{icons.applicationEditOutline}}</v-icon>
                  </v-btn>
                  </template>
                  <span>ナビの詳細</span>
              </v-tooltip>
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }"> 
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon
                    @click="renameItem(item)"
                  >
                    <v-icon>{{icons.pencil}}</v-icon>
                  </v-btn>
                  </template>
                  <span>名前の変更</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }"> 
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon
                    @click="deleteItem(item)"
                  >
                    <v-icon>{{icons.delete}}</v-icon>
                  </v-btn>
                </template>
                <span>アイテムの削除</span>
              </v-tooltip>

              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }"> 
              
                  <v-btn 
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon
                    @click="changeThumbnail(item)"
                  >
                    <v-icon>{{icons.image}}</v-icon>
                  </v-btn>
                </template>
                <span>サムネイルの変更</span>
              </v-tooltip>

              
              <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }"> 
              
                  <v-btn 
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon
                    @click="copyNavi(item)"
                  >
                    <v-icon>{{icons.contentCopy}}</v-icon>
                  </v-btn>
                </template>
                <span>ナビのコピー生成</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }"> 
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mr-4 my-auto"
                    icon
                    @click="makeQRCode(item)"
                  >
                    <v-icon>{{icons.qrcode}}</v-icon>
                  </v-btn>
                </template>
                <span>QRCodeの表示</span>
              </v-tooltip>

            </div>
          </div>        
        </v-hover>
      </template>

    <!-- 公開設定 -->
    <template v-slot:item.publish="{ item }">
      <v-btn outlined text class="ma-0 pa-1" color="narekomu" @click="changePublish(item)" >{{convertPublishName(item.publish)}}</v-btn>
    </template>

    <!-- 表示設定 -->
    <template v-slot:item.show="{ item }">
      <v-btn  text icon @click="changeShow(item)">
        <v-icon v-if="item.show" color="grey">{{icons.eye}}</v-icon>
        <v-icon v-else color="grey">{{icons.eyeOff}}</v-icon>
      </v-btn>
    </template>
      

    <template v-if="!isTop" v-slot:top>
        <v-container>          
          <v-row>
            <!-- リロード -->
            <v-col  cols=4 sm=4 md=1 lg=1 xl=1  >
              <v-btn 
                dense
                :disabled="loading"
                 :width="($vuetify.breakpoint.xs)?'100%':'90%'"
                class="mb-2"
                @click="getData"
              >
                <v-icon dense >{{icons.reload}}</v-icon>
              </v-btn>
            </v-col>

            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
      
            <!-- 検索バー -->
            <v-col :order="($vuetify.breakpoint.xs)?'first':''" cols=12 sm=5 md=3 lg=3 xl=3 :class="($vuetify.breakpoint.xs)?'pb-0':'pa-1'">
              <v-text-field
                v-model="search"
                :append-icon="icons.magnify"
                class="mb-2 shrink"
                label="Search"
                single-line
                hide-details   
              ></v-text-field>
            </v-col>
          <!-- 使用容量 -->
            <v-col  cols=8 sm=4 md=3 lg=3 xl=2  >
              <div style="width:97%" :class="($vuetify.breakpoint.xs)?'':'mx-0  pa-0'">
                <v-progress-linear
                  :value="makeRate"
                  rounded
                  disabled
                  height="35"
                  color="narekomu"
                  background-color="grey lighten-3"
                  
                >
                <div class="  grey--text text--darken-2">  {{ makeRateText }} </div>
                </v-progress-linear>
              </div>
              
            </v-col>

          </v-row>
        </v-container>

      
      <v-expand-transition>
        <v-card
          v-show="selected.length"
          elevation="0"
          height="70"
          width="100%"
          class="mx-auto"
          style="border-radius:0; background-color:rgb(29, 29, 29);;"
        >
          <div class="d-flex justify-start py-3" style="height:70px; width:100%;" >

              <div class="mx-5 mt-2" style="color:white;">{{selected.length}}件選択されました</div>
              <v-divider 
                vertical
                color="grey"
                class="mx-1"
              ></v-divider>
              <v-btn 
                color="white"
                class="mt-1 white--text"
                width="48%"
                text
                max-width="100px"
                :disabled="(selected.length==0)"
                @click="dialogDelete=true "
                >
                <p  class="my-auto"> 削除する </p>
              </v-btn>

              <v-divider 
              ></v-divider>

              <v-btn 
                icon
                large
                color="white"
                class="mt-1 mr-3"
                @click="selectDelete()"
              >
                <v-icon >{{icons.close}}</v-icon>
              </v-btn>

            </div>
        
        </v-card>
      </v-expand-transition>


        <!-- ダイアログ /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-->
        <v-dialog v-model="dialog" max-width="700px" loading="false" persistent style="z-index:2001;">
          <DialogRename 
            v-on:closeRenameEmit="close"
            v-on:renameItemExeEmit="renameItemExe"
            :name="renamedItem.naviName"
            :id="renamedItem.id"
            :desserts="desserts"
            typeName="ナビ"
            type="navi"

          />
        </v-dialog>


        <v-dialog v-model="dialogDelete" max-width="600px" loading="false" persistent style="z-index:2001;">
          <DialogDelete 
            v-on:closeDeleteEmit="close"
            v-on:deleteItemExeEmit="deleteItemExe"
            :name="deletedItemName"
            :deleteList="selected"
            typeId="naviId"
            type="navi"

          />
          
        </v-dialog>

        <v-dialog v-model="dialogChangeThumb" max-width="600px" persistent style="z-index:2002;">
          <DialogThumbnailChange
              :thumbnail="targetItem.thumbnail"
              :id="targetItem.naviId"
              type="navi"
              v-on:closeEmit="close"
              v-on:saveEmit="saveThumbnail"
          />
        </v-dialog>

        <v-dialog v-model="dialogPublish" max-width="600px" loading="false" persistent style="z-index:2001;">
          <DialogPublish
            :item="publishItem"
            :isTable="true"
            :change="publishItem.naviId"
            v-on:closeEmit="close"
            v-on:savePublishEmit="savePublish"
          />
        </v-dialog>

         <v-dialog v-model="dialogShow" max-width="300px" loading="false" persistent style="z-index:2001;">
          <DialogShow
            :item="targetItem"
            v-on:closeEmit="close"
            v-on:saveShowEmit="saveShow"
          />
        </v-dialog>

        <v-dialog v-model="dialogQRCode" max-width="600px" persistent style="z-index:2001;">
          <DialogQRCode
            v-on:closeQRCodeEmit="close"
            :name="qrName"
            :id="qrId"
          />
        </v-dialog>

        <v-dialog v-model="dialogImage" max-width="850px" width="90%"  loading="false" persistent style="z-index:2001;">
          <DialogImageView
            :url="targetItem.thumbnail" 
            loading=false
            :name="targetItem.naviName"
            :item="targetItem"
            type="navi"
            v-on:closeEmit="close"
            v-on:thumbnailChangeEmit="changeThumbnail" 
          />
        </v-dialog>

        <v-dialog v-model="dialogCopyNavi" max-width="600px" persistent style="z-index:2001;">
          <DialogCopyNavi
            :item="targetItem" 
            v-on:copyNaviEmit="copyNaviExe"
            v-on:closeEmit="close"
          />
        </v-dialog>

      <!-- </v-toolbar> -->
    </template>



  

    <!-- アクション -->
    <template v-if="!isTop" v-slot:item.actions="{ item }">
      <div class="d-flex">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }"> 
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="renameItem(item)"
            >
              {{icons.pencil}}
            </v-icon>
            </template>
            <span>名前の変更</span>

        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }"> 
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="deleteItem(item)"
            >
              {{icons.delete}}
            </v-icon>
          </template>
          <span>アイテムの削除</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }"> 
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="makeQRCode(item)"
            >
              {{icons.qrcode}}
            </v-icon>
          </template>
          <span>QRCodeの表示</span>
        </v-tooltip>
      </div>
    </template>
  
    <template v-if="!$vuetify.breakpoint.xs&& isTop " v-slot:item.no="{item}">
      <div v-if="item.no==1" class="font-weight-bold text-h3 yellow--text text-center text--darken-2 d-flex flex-column" style="width:100%;"  >
        <v-icon color="yellow darken-1 " class="mx-auto" large >{{icons.crown}}</v-icon> {{item.no}}</div>
      <div v-else-if="item.no==2" class="font-weight-bold text-center text-h4 grey--text d-flex flex-column">
          <v-icon color="grey darken-1" class="mx-auto" >{{icons.crown}}</v-icon> {{item.no}}</div>
      <div v-else-if="item.no==3" class="font-weight-bold text-center text-h5 brown--text  d-flex flex-column">
          <v-icon color="brown darken-1" class="mx-auto" small >{{icons.crown}}</v-icon> {{item.no}}</div>
      <div v-else>{{item.no}}</div>
    </template>
    <!-- スマホ用 -->
      <template v-slot:body="{ items: item }" v-if="$vuetify.breakpoint.xs">
      <v-progress-linear v-if="loading"
        indeterminate
        color="narekomu"
      ></v-progress-linear>
      <tbody>
        <tr v-for="i in item" :key="i.naviId" >   
          <td v-if="!isTop" class="ma-0 px-1 text-center">
            <v-checkbox v-model="selected" :value="i" style="margin:0px;padding:0px" hide-details />
          </td>
          <td v-if="isTop"  :class="i.no<=3? 'pa-1': 'pa-3'" >
            <div v-if="i.no==1" class="font-weight-bold text-h4 yellow--text text-center text--darken-2 d-flex flex-column "   >
              <v-icon color="yellow darken-1 mx-auto"  >{{icons.crown}}</v-icon> {{i.no}}</div>
            <div v-else-if="i.no==2" class="font-weight-bold  text-center text-h5 grey--text d-flex flex-column">
              <v-icon color="grey darken-1"  >{{icons.crown}}</v-icon> {{i.no}}</div>
            <div v-else-if="i.no==3" class="font-weight-bold text-center text-h6 brown--text  d-flex flex-column">
                <v-icon color="brown darken-1"  >{{icons.crown}}</v-icon> {{i.no}}</div>
            <div v-else>{{i.no}}</div> 
          </td>
          <td class="ma-0 pa-0 pl-2"  min-width="160px" >
            <TableThumbnail
              :item="i"
              idName="naviId"
              type="navi"
              :isTop="isTop"
              :change="toggleChart"
              v-on:changeThumbnailEmit="changeThumbnail"
              v-on:saveEmit="saveThumbnail"
              v-on:showContentEmit="showContent"
            />
          </td>
          <td class="ml-2 mr-0 pr-0 " style="width:75%;">
            <div class="d-flex flex-column">
              <div class="d-flex justify-space-between">
                <p class="font-weight-bold my-0" width="80%" style="word-break: break-all; ">    
                  <router-link style="color:#4ECDC4;" :to="{
                      name: 'naviDetail',
                      params: { id: i.naviId },
                      query:{hash:hashText}
                  }">
                    {{i.naviName}}
                  </router-link>
                </p>
              <v-menu 
              left  
              offset-y 
              :nudge-width="200"
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation=0
                  small
                  icon
                  v-bind="attrs"
                  :ripple="false"
                  v-on="on"
                  dense 
                  color="gray"
                  class="mr-2"
                >
                  <v-icon small class="ma-0 pa-0">
                    {{icons.dotsVertical}}
                  </v-icon>
                </v-btn>
              </template>
              <v-list>

                <v-list-item dense btn  @click="renameItem(i)">
                    <v-list-item-icon>
                      <v-icon>{{icons.pencil}}</v-icon>
                    
                    </v-list-item-icon>
                  <v-list-item-title> 名前の変更</v-list-item-title>
                </v-list-item>

                <v-list-item dense btn  @click="deleteItem(i)">
                    <v-list-item-icon>
                      <v-icon>{{icons.delete}}</v-icon>
                
                    </v-list-item-icon>
                  <v-list-item-title> 削除</v-list-item-title>
                </v-list-item>


                <v-list-item dense btn  @click="changeThumbnail(i)">
                    <v-list-item-icon>
                      <v-icon>{{icons.image}}</v-icon>
                    
                    </v-list-item-icon>
                  <v-list-item-title> サムネイルの変更</v-list-item-title>
                </v-list-item>

                <v-list-item dense btn  @click="copyNavi(i)">
                  <v-list-item-icon>
                    <v-icon>{{icons.contentCopy}}</v-icon>
                  
                  </v-list-item-icon>
                  <v-list-item-title> ナビのコピー生成</v-list-item-title>
                </v-list-item>


                <v-list-item dense btn  @click="makeQRCode(i)">
                  <v-list-item-icon>
                    <v-icon>{{icons.qrcode}}</v-icon>
                  
                  </v-list-item-icon>
                  <v-list-item-title> QRCodeの表示</v-list-item-title>
                </v-list-item>

              </v-list>
            </v-menu>
              </div>
              <p class="caption mr-auto mt-0 mb-1 text--secondary"  > {{i.createTime}} </p>
             <p class="caption text--secondary align-end flex-column mb-0"> <span class="body-2 text--secondary ml-auto" >表示回数:</span> {{i.naviCount}}</p>
            <div style="width:100%" class="text-right">
              <v-btn width="10px" height="20px"  text class="ma-0 pa-2 caption justify-end " color="narekomu" @click="changePublish(i)" >{{convertPublishName(i.publish)}}</v-btn>
            </div>
          
            </div>
          </td>
        </tr>
      </tbody>
    </template>

    <template v-slot:item.thumbnail="{ item }  ">
      <TableThumbnail
        :item="item"
        idName="naviId"
        type="navi"
        :isTop="isTop"
        :change="toggleChart"
        v-on:changeThumbnailEmit="changeThumbnail"
        v-on:saveEmit="saveThumbnail"
        v-on:showContentEmit="showContent"
        />
    </template>


    <template v-slot:item.naviCount="{ item }  "> 

      <div class="text-left pl-4">{{item.naviCount}}</div>
    </template> 



    </v-data-table>

  </div>
</template> 


<script>
import axios from "axios"
import { mdiApplicationEditOutline,  mdiClose, mdiContentCopy, mdiCrown, mdiDelete, mdiDotsVertical, mdiDownload, mdiEye, mdiEyeOff, mdiFilePdfBox,  mdiImage, mdiMagnify, mdiPencil, mdiQrcode, mdiReload, mdiRestore,  mdiVideo3d, mdiVideoBox } from '@mdi/js';

const TableThumbnail = () => import('@/components/TableThumbnail.vue');
const DialogDelete = () => import('@/components/DialogDelete.vue');
const DialogRename = () => import('@/components/DialogRename.vue');
const DialogThumbnailChange = () => import('@/components/DialogThumbnailChange.vue');
const DialogImageView = () => import('@/components/DialogImageView.vue');
const DialogQRCode = () => import('@/components/DialogQRCode.vue');
const DialogPublish = () => import('@/components/DialogPublish.vue');
const DialogCopyNavi = () => import('@/components/DialogCopyNavi.vue');
const DialogShow = () => import('@/components/DialogShow.vue');

export default {
  name: 'NaviTable',
  props:["isTop","pToggle","popularList"],
  components :{
    // BarChart,
    TableThumbnail,
    DialogDelete,
    DialogRename,
    DialogThumbnailChange,
    DialogQRCode,
    DialogPublish,
    DialogShow,
    DialogImageView,
    DialogCopyNavi
  },
  data () {
    return {
      icons:{
        "applicationEditOutline":mdiApplicationEditOutline,
        "magnify":mdiMagnify,
        "close":mdiClose,
        "pencil":mdiPencil,
        "delete":mdiDelete,
        "contentCopy":mdiContentCopy,
        "download":mdiDownload,
        "image":mdiImage,
        "restore":mdiRestore,
        "qrcode":mdiQrcode,
        "eye":mdiEye,
        "eyeOff":mdiEyeOff,
        "crown":mdiCrown,
        "reload":mdiReload,
        "video3d":mdiVideo3d,
        "filePdfBox":mdiFilePdfBox,
        "videoBox":mdiVideoBox,
        "dotsVertical":mdiDotsVertical,
      },
      items: null,
      renameItemName:"",
      selected:[],
      search:"",
      loading: true,
      toggleChart:false,
      dialog: false,
      dialogDelete: false,
      dialogChangeThumb:false,
      dialogQRCode:false,
      dialogPublish:false,
      dialogImage:false,
      dialogCopyNavi:false,
      dialogShow:false,
      publishItem:{},
      qrName:"",
      qrId:"",
      targetThumbSrc:"",
      targetItem:{
        thumbnail:"",
        id:""
      },
      stepSize: 1,
      maxCount: 1,
      editedIndex: -1,
      desserts: [],
      ranking:[],
      loaded:false,
      usageRate:0,
      usageText:"",
      itemsPerPage:10,
      page: 1,
      deletedItemName:"",
       renamedItem: {
        name: '',
        id: '',
      },
      sortbyList:["no","createTime"],
      sortdescList:[false,true],
      sortHashDict:{
        columnType:"createTime",
        sortOrder:"descending",
        page:1,
        search:""},
      hashText:"",
      tmpPage:1,

    }
  },
  computed: {
    pageText(){
      return `${Math.max(1,(this.page-1)*this.itemsPerPage)} - ${Math.min(this.page*this.itemsPerPage,this.totalRecords)} of ${this.totalRecords} `
    },
    totalRecords() {
      return this.desserts.length
    },
    
    pageCount() {
      return Math.ceil(this.totalRecords / this.itemsPerPage)
    },
    // isUser: function(){
    //   return this.$store.state.user_model.userData;
    // },

    //使用料の計算
    makeRateText(){
      let s = this.$store.state.user_model.userData["navi"]
      let usage = this.desserts.length;
      let rate = Math.round(usage/s.limit*100*10)/10;
      let unit = "個";
      return ` ${usage} / ${s.limit} ${unit} ( ${rate} %) `;
    },

    makeRate(){
      let s = this.$store.state.user_model.userData["navi"]
      let usage = this.desserts.length;
      let rate = Math.round(usage/s.limit*100*10)/10;
      return rate;
    },

    renameTitle () {
      return this.renameItem.name
    },
    headers () {
      if(this.isTop){
         return [
        {
          text: 'No',
          sortable:false,
          align:"center",
          value: 'no',
          width: "10%",
        },
        {
          text: 'サムネイル',
          sortable: false,
          value: 'thumbnail',
          width: "10%",
        },
        {
          text: '名前',
          align: 'start',
          sortable: false,
          value: 'naviName',
          width: "45%",
        },
        {
          text: 'アップロード日',
          sortable: false,
          value: 'createTime',
          width: "15%",
        },
        {
          text: '表示回数',
          sortable: false,
          value: 'rankCount',
          width: "20%",
        },
     
      ]
      }
      else{
        return [
          {
            text: '',
            value: 'data-table-select',
            width: 20
          },
          {
            text: 'サムネイル',
            sortable: false,
            value: 'thumbnail',
            width: "10%",
          },
          {
            text: '名前',
            align: 'start',
            sortable: true,
            value: 'naviName',
            width: "40%",
          },
          {
            text: 'アップロード日',
            sortable: true,
            value: 'createTime',
            width: "20%",
          },
          {
            text: '公開設定',
            sortable: true,
            value: 'publish',
            width: "10%",
          },
          {
            text: '表示設定',
            sortable: true,
            value: 'show',
            width: "10%",
          },
          {
            text: '表示回数',
            sortable: true,
            value: 'naviCount',
            width: "10%",
          },
          // { text: 'アクション',
          //   sortable: false ,
          //   value: 'actions',
          //   width: "15%",
          // },
        ]
      }
    },
  },

  watch :{
    search : function(){
      this.sortHashDict.search = this.search;
      this.reChart();
    },
    // isUser:{
    //   handler(){
    //     this.makeRate();
    //   },
    //   deep: true,
    // },

    sortHashDict:{
      handler(newVal){
        this.hashText = "";
        for (let key in newVal) {
          this.hashText += `${key}:${newVal[key]},`
        }
        // location.hash=this.hashText;
      },
      deep: true,
    },
    page:function(){
      if(this.page){
        this.sortHashDict.page = this.page;  
      }else{
        this.page = 1;
      }
      setTimeout(this.reChart,100)
    },

    popularList:{
      handler(){
        //すでにナビデータが準備されていた場合ランキング作成
        if(this.isTop & this.loaded){
          this.makeRanking(this.popularList)
        }
      },
      deep: true,
    },
  },

  async created() {
    this.setSort();
    // this.makeRate();
    await this.getData();
    this.page= Number(this.tmpPage);
  
    // if(this.isTop){this.makeRanking(this.popularList);}

    this.sortTable();

   
    setTimeout(this.reChart,500);
  },
  

  methods: {
    // ナビのコピーを作成する
    copyNavi: async function (item) {
      this.dialogCopyNavi = true;
      this.targetItem = item;
    },

    // ナビのコピーを作成する
    copyNaviExe: async function (item) {
        this.desserts.push(item);
        this.reChart();
        this.close();
    },

    // getパラメータからtableにfilterをかける
    setSort(){
      if(location.hash){
        let hash = location.hash.substr(1);
        let hashes = hash.split(',');
        let tmpDict = {};
        for(let i = 0 ; i < hashes.length-1 ; i++) {

          let hashKeyValue = hashes[i].split(':');
          let key = hashKeyValue[0];
          let value = hashKeyValue[1];
          tmpDict[key] = value;

        }
        this.sortHashDict = tmpDict;
        this.sortbyList[1] = tmpDict.columnType;
        this.sortdescList[1] = (tmpDict.sortOrder == "descending");
        this.tmpPage = tmpDict.page;
        this.search = decodeURI(tmpDict.search);
    
      }
    },

    //ナビの詳細に遷移する
    goNaviDetail(item){
      location.hash=this.hashText
      this.$router.push({
        name:'naviDetail',
        params:{id : item.naviId},
        query:{hash:this.hashText}
      })
    },

    //ナビ一覧の取得
    getData: async function () {
      this.loading = true;
      this.desserts = [];
      await axios({
        method: "GET",
        url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/navis`,
        headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
      }).then((response) => {
        this.desserts = response.data.results;
        this.loading = false;
        this.loaded = true;
        if(this.isTop && this.popularList != []){
          this.makeRanking(this.popularList)
        }
        this.addReChart();
      
        setTimeout(this.reChart,100) 

      });
    },

    //ナビランキングの作成(TopPageのみ)
    makeRanking(array){
      if(this.loaded){
        let tmp = this.desserts;
        let number = 1; 
        this.ranking = [];
        for(let i = 0; i < array.length & number <= 10 ; i++){
          for(let j in tmp){
            if( tmp[j].naviId == array[i].key ){
              tmp[j].no = number;
              tmp[j].rankCount = array[i].value;
              this.ranking.push(tmp[j])
              number++;
              break;
            }
          }
        }
        this.loading = false;
        setTimeout(this.reChart,100)
      }
    },

    //サムネイル、グラフの再描写のボタン
    addReChart(){
      let elems = document.getElementsByClassName("v-data-footer__icons-before");
      for(let i = 0 ; i < elems.length; i++){
        elems[i].addEventListener('click',{handleEvent: this.reChart });
      }
      elems = document.getElementsByClassName("v-data-footer__icons-after");
      for(let i = 0 ; i < elems.length; i++){
        elems[i].addEventListener('click',{handleEvent: this.reChart });
      }
      elems = document.getElementsByClassName("sortable");
      for(let i = 0 ; i < elems.length; i++){
        elems[i].addEventListener('click',{handleEvent: this.reChart });
      }
    },

    //サムネイル変更ダイアログの表示
    changeThumbnail(item){
      this.targetItem = item;
      console.log("dd")
      this.dialogChangeThumb = true;
    },

    //グラフの再描写
    reChart() {        
      this.toggleChart = !this.toggleChart;
    },

    convertPublishName(publish){
      switch(publish){
        case 'unlisted' : return "非公開" 
        case 'private'  : return "限定公開"
        case 'public'   : return "全体公開"
        default:  return  "非公開"
      }
    },

    renameItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.renamedItem.naviName = item.naviName;
      this.renamedItem.id = item.naviId
      this.dialog = true
    },

    renameItemExe(newName){
      this.renamedItem.naviName = newName;
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.renamedItem)
      } 
      this.close();
    },

    selectDelete(){
      this.selected = [];
      setTimeout(this.reChart,100)
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.deletedItemName = item.naviName;
      this.selected.push(item);
      this.dialogDelete = true;
    },

    deleteItemExe(items){
      let id = null;
      Object.keys(items).forEach(key =>{
          id = this.desserts.indexOf(items[key])
          this.desserts.splice(id, 1)
      })
      this.close();
    },

    saveThumbnail(img){
      let item = this.targetItem;
      this.editedIndex = this.desserts.indexOf(item)
      item.thumbnail = img;
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], item)
      } 
      this.close();
    },

    showContent(item){
      this.targetItem = item;
      this.dialogImage = true;
    },

    //QRCodeの生成
    makeQRCode(item){
      this.dialogQRCode = true;
      this.qrName = item.naviName;
      this.qrId = item.naviId;

    },

    changePublish(item){
      this.editedIndex = this.desserts.indexOf(item)
      this.dialogPublish = true;
      this.publishItem = item;

    },

    changeShow(item){
      this.editedIndex = this.desserts.indexOf(item)
      this.dialogShow = true;
      this.targetItem = item;
    },

    savePublish(item){
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], item)
      } 
      this.close();
    },

    
    saveShow(item){
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], item)
      } 
      this.close();
    },





    sortTable(){
      let headList = ["naviName","createTime","publish","naviCount"]
      let elems = document.getElementsByClassName("sortable");
      const self = this;
      for(let i = 0 ; i < elems.length; i++){
        elems[i].addEventListener('click',{handleEvent: function(){
          self.sortHashDict.columnType = headList[i];
          self.sortHashDict.sortOrder = elems[i]["ariaSort"];
        } });
      }
    },

    close () {
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogImage = false;
      this.dialogChangeThumb = false;
      this.dialogQRCode = false;
      this.dialogShow = false;
      this.deletedItemName=null;
      this.selected = [];
      this.targetItem = {},
      this.dialogPublish = false;
      this.dialogImage = false;
      this.dialogCopyNavi = false;
      this.$nextTick(() => {
        this.editedIndex = -1
      })
      setTimeout(this.reChart,100)
    },

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.text-start {
  padding: none;
}

v-data-table >>> div > table {
  border-spacing: 0 0.5rem;
}


</style>
